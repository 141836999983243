import { bindable } from 'aurelia-templating';
import { log } from 'console';
import daterangepicker, { Options } from 'daterangepicker';
import moment from 'moment';
import { IDateRange } from './IDateRange';
import { computedFrom } from 'aurelia-framework';

export class DateRangePicker {
    @bindable() private dateRange: IDateRange = {
        from: DateRangePicker.defaultRange[0],
        until: DateRangePicker.defaultRange[1]
    };
    @bindable() private minDate: moment.Moment;
    @bindable() private options: Options;
    @bindable() private enableCalendarIcon: boolean = true;

    private static startOfToday = () => moment().utc().startOf('day');
    private static endOfToday = () => moment().utc().endOf('day');
    private static daysAgo = (n: number) => moment().utc().subtract(n, 'day').startOf('day');
    /**
     * Collection of default date/time ranges.
     */
    public static ranges = {
        'Now': [
            DateRangePicker.daysAgo(1),
            DateRangePicker.endOfToday()
        ],

        'Today': [
            DateRangePicker.startOfToday(),
            DateRangePicker.endOfToday()
        ],

        'Yesterday': [
            DateRangePicker.daysAgo(1),
            DateRangePicker.daysAgo(1).endOf('day')
        ],

        'Last 7 Days': [
            DateRangePicker.daysAgo(6),
            DateRangePicker.endOfToday()
        ],

        'Last 8 Days': [
            DateRangePicker.daysAgo(7),
            DateRangePicker.endOfToday()
        ],

        'Last 30 Days': [
            DateRangePicker.daysAgo(29),
            DateRangePicker.endOfToday()
        ],

        'This Month': [
            moment().utc().startOf('month'),
            DateRangePicker.endOfToday()
        ],

        'Last Month': [
            moment().utc().subtract(1, 'month').startOf('month'),
            moment().utc().subtract(1, 'month').endOf('month').add(1, 'seconds')
        ],

        'Last 6 Months': [
            moment().utc().subtract(6, 'month').startOf('month'),
            DateRangePicker.endOfToday()
        ]
    };
    public static readonly customRangeLabel = 'Custom Range';
    public static readonly defaultRangeLabel = 'Now';
    public static readonly defaultRange = DateRangePicker.ranges[DateRangePicker.defaultRangeLabel];
    private static readonly dateFormat: string = 'YYYY/MM/DD HH:mm';
    private dateRangePickerElement: HTMLElement;
    private dateRangePicker: daterangepicker;

    public reset(): void {
        const defaultDateRangeLabel = 'Now';
        const defaultDateRange = DateRangePicker.ranges[defaultDateRangeLabel];
        this.dateRange = {
            from: defaultDateRange[0],
            until: defaultDateRange[1]
        };
    }

    private async attached(): Promise<void> {
        await this.construct();
    }

    private detached(): void {
        this.destruct();
    }

    private async construct(): Promise<void> {
        this.initialize();
    }

    private destruct(): void {
        // Removes the daterangepicker component
        this.dateRangePicker.remove();
    }

    /**
     * Initializes the daterangepicker with the currently set values in the config
     */
    private initialize(): void {
        const config: Options = {
            ranges: this.getRanges(this.minDate),
            autoApply: true,
            locale: {
                format: DateRangePicker.dateFormat,
                customRangeLabel: DateRangePicker.customRangeLabel
            },
            opens: 'right',
            timePicker: false,
            timePicker24Hour: true,
            startDate: this.dateRange.from,
            endDate: this.dateRange.until,
            ...this.options
        };

        this.dateRangePicker = new daterangepicker(
            this.dateRangePickerElement,
            config,
            (start: moment.Moment, end: moment.Moment, label) => {
                this.dateRange = {
                    from: start ? start.startOf('day') : undefined,
                    until: end ? end.endOf('day') : undefined
                };
            }
        );
    }

    /**
     * Retrieves the ranges based on a minimum given date
     * @param minDate The minimum date to retrieve date ranges for,
     * if minDate is a string, retrieve the minDate from the ranges array
     */
    private getRanges(minDate?: moment.Moment | string): any {
        // No max date range specified, return all ranges without filtering
        if (!minDate) return DateRangePicker.ranges;

        // Set max date to either maxDateRange if it's actually a moment or get the moment from the range by label
        const minDateMoment: moment.Moment = moment.isMoment(minDate)
            ? minDate as moment.Moment
            : DateRangePicker.ranges[minDate as string][0];

        const ranges = {};

        for (const label in DateRangePicker.ranges) {
            if (DateRangePicker.ranges[label]) {
                const range = DateRangePicker.ranges[label];
                if (minDateMoment.isSameOrBefore(range[0])) {
                    ranges[label] = range;
                }
            }
        }

        return ranges;
    }

    public isActive(): boolean {
        const dateRangePickerElements = document
            .getElementsByClassName('daterangepicker');

        if (dateRangePickerElements.length > 1)
            throw new Error('Multiple date range pickers are active.');

        if (dateRangePickerElements.length === 0)
            throw new Error('No date range picker is active, expected one to be active.');

        const dateRangePickerElement = dateRangePickerElements.item(0);
        const styleAttribute = dateRangePickerElement.getAttribute('style');

        // When the element has no style attribute, it's not visible/open
        if (!styleAttribute)
            return false;

        return styleAttribute.valueOf().includes('display: block');
    }

    public static isRange(dateRange: IDateRange, rangeLabel: string): boolean {
        const range = this.ranges[rangeLabel];
        return dateRange.from.isSame(range[0])
            && dateRange.until.isSame(range[1]);
    }

    @computedFrom('dateRange.from', 'dateRange.until')
    public get rangeLabel(): string {
        let label = DateRangePicker.customRangeLabel;

        for (const rangeLabel in DateRangePicker.ranges) {
            // Keep the TS gods happy by making extra sure the label exist in the ranges object
            if (!DateRangePicker.ranges[rangeLabel]) continue;

            const range = DateRangePicker.ranges[rangeLabel];

            if (this.dateRange.from.isSame(range[0])
                && this.dateRange.until.isSame(range[1]))
                label = rangeLabel;
        }

        return label;
    }
}
