import { autoinject, bindable } from 'aurelia-framework';
import Swal from 'sweetalert2';
import { Toast } from 'utilities/toast';
import { Utilities } from 'utilities/utilities';
import {
    AlertCountermeasureSchema,
    AlertDetailSchemaActionSteps,
    CounterMeasuresApiClient,
    SicAlert
} from './../../services/cyber-api';
import { StateApi } from './../../services/state-api';

@autoinject
export class CounterMeasures {
    @bindable() private counterMeasures: AlertCountermeasureSchema[];
    @bindable() private threat: SicAlert;
    @bindable() private isXChange: boolean = false;

    constructor(
        private counterMeasuresApi: CounterMeasuresApiClient,
        private state: StateApi
    ) { }

    private async activateCounterMeasure(countermeasure: AlertCountermeasureSchema): Promise<void> {
        if (!this.mayCountermeasureStatusBeChanged(countermeasure, this.threat.status.name)) return;

        Swal.fire({
            title: 'Report as Implemented',
            html: `Are you sure you want to report that you have executed this countermeasure on your network?<br><br>${countermeasure.description}`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, report',
            input: 'textarea',
            inputPlaceholder: 'A comment is optional but could be very useful for backtracking'
        })
            .then(async (result) => {
                if (result.dismiss) return;

                // Display a loader in a similar swal style
                Swal.fire({ title: 'Reporting as implemented...', html: '<i class="fal fa-spinner-third fa-spin fa-3x my-3"></i>', showConfirmButton: false });

                try {
                    const updatedCm = await this.counterMeasuresApi.activate(countermeasure.uuid, this.state.company(), result.value || "");

                    // Update the countermeasure in the list, showing the updated status in real time
                    this.updateLocalCountermeasure(updatedCm);

                    Toast.success('Countermeasure successfully reported');
                } catch (error) {
                    Toast.error(`Oops, ${error}`);
                    throw error;
                } finally {
                    Swal.close();
                }
            })
            .catch((error) => {
                Swal.showValidationMessage(`Operation failed: ${error}`);
            });
    }

    private async denyCounterMeasure(countermeasure: AlertCountermeasureSchema): Promise<void> {
        if (!this.mayCountermeasureStatusBeChanged(countermeasure, this.threat.status.name)) return;

        Swal.fire({
            title: 'Ignore Countermeasure',
            html: `Are you sure you want to report that you consider this countermeasure irrelevant / impossible to apply?<br><br>${countermeasure.description}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, ignore',
            input: 'textarea',
            inputPlaceholder: 'A comment is optional but could be very useful for backtracking'
        })
            .then(async (result) => {
                if (result.dismiss) return;

                // Display a loader in a similar swal style
                Swal.fire({ title: 'Ignoring Countermeasure...', html: '<i class="fal fa-spinner-third fa-spin fa-3x my-3"></i>', showConfirmButton: false });

                try {
                    const updatedCm = await this.counterMeasuresApi.deny(countermeasure.uuid, this.state.company(), result.value || "");

                    // Update the countermeasure in the list, showing the updated status in real time
                    this.updateLocalCountermeasure(updatedCm);

                    Toast.success('Countermeasure successfully ignored');
                } catch (error) {
                    Toast.error(`Oops, ${error}`);
                    throw error;
                } finally {
                    Swal.close();
                }
            })
            .catch((error) => {
                Swal.showValidationMessage(`Operation failed: ${error}`);
            });
    }

    private async activateActionStep(actionStep: AlertDetailSchemaActionSteps, countermeasure: AlertCountermeasureSchema): Promise<void> {
        if (!this.mayActionStepStatusBeChanged(actionStep, countermeasure, this.threat.status.name)) return;

        Swal.fire({
            title: 'Report as Implemented',
            html: `Are you sure you want to report that you have executed this action on your network?<br><br>${actionStep.description}`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, report',
            input: 'textarea',
            inputPlaceholder: 'A comment is optional but could be very useful for backtracking'
        })
            .then(async (result) => {
                if (result.dismiss) return;

                // Display a loader in a similar swal style
                Swal.fire({ title: 'Reporting as implemented...', html: '<i class="fal fa-spinner-third fa-spin fa-3x my-3"></i>', showConfirmButton: false });

                try {
                    const updatedActionStep = await this.counterMeasuresApi.activateActionStep(countermeasure.uuid, actionStep.name, this.state.company(), result.value || "");

                    // Update the action step in the list, showing the updated status in real time
                    this.updateLocalActionStep(updatedActionStep, countermeasure);

                    Toast.success(`Action successfully reported`);
                } catch (error) {
                    Toast.error(`Oops, ${error}`);
                    throw error;
                } finally {
                    Swal.close();
                }
            })
            .catch((error) => {
                Swal.showValidationMessage(`Operation failed: ${error}`);
            });
    }

    private async denyActionStep(actionStep: AlertDetailSchemaActionSteps, countermeasure: AlertCountermeasureSchema): Promise<void> {
        if (!this.mayActionStepStatusBeChanged(actionStep, countermeasure, this.threat.status.name)) return;

        Swal.fire({
            title: 'Ignore Action',
            html: `Are you sure you want to report that you consider this action irrelevant / impossible to apply?<br><br>${actionStep.description}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, ignore',
            input: 'textarea',
            inputPlaceholder: 'A comment is optional but could be very useful for backtracking'
        })
            .then(async (result) => {
                if (result.dismiss) return;

                // Display a loader in a similar swal style
                Swal.fire({ title: 'Ignoring action...', html: '<i class="fal fa-spinner-third fa-spin fa-3x my-3"></i>', showConfirmButton: false });

                try {
                    const updatedActionStep = await this.counterMeasuresApi.denyActionStep(countermeasure.uuid, actionStep.name, this.state.company(), result.value || "");

                    // Update the action step in the list, showing the updated status in real time
                    this.updateLocalActionStep(updatedActionStep, countermeasure);

                    Toast.success('Action successfully ignored');
                } catch (error) {
                    Toast.error(`Oops, ${error}`);
                    throw error;
                } finally {
                    Swal.close();
                }
            })
            .catch((error) => {
                Swal.showValidationMessage(`Operation failed: ${error}`);
            });
    }

    private mayCountermeasureStatusBeChanged(counterMeasure: AlertCountermeasureSchema, threatStatus: string): boolean {
        return counterMeasure.status !== 'activated'
            && counterMeasure.status !== 'denied'
            && threatStatus !== 'Rejected'
            && threatStatus !== 'Closed';
    }

    private mayActionStepStatusBeChanged(actionStep: AlertDetailSchemaActionSteps, counterMeasure: AlertCountermeasureSchema, threatStatus: string): boolean {
        return actionStep.status !== 'activated'
            && actionStep.status !== 'denied'
            && counterMeasure.status !== 'activated'
            && counterMeasure.status !== 'denied'
            && threatStatus !== 'Rejected'
            && threatStatus !== 'Closed';
    }

    private updateLocalCountermeasure(countermeasure: AlertCountermeasureSchema): void {
        // Update the countermeasure in the list, showing the updated status in real time
        const index = this.counterMeasures.findIndex((cm) => cm.uuid === countermeasure.uuid);
        this.counterMeasures.splice(index, 1, countermeasure);
    }

    private updateLocalActionStep(actionStep: AlertDetailSchemaActionSteps, countermeasure: AlertCountermeasureSchema): void {
        // Update the countermeasure in the list, showing the updated status in real time
        const countermeasureIndex = this.counterMeasures.findIndex((cm) => cm.uuid === countermeasure.uuid);
        const foundCountermeasure = this.counterMeasures[countermeasureIndex];
        const actionStepIndex = foundCountermeasure.action_steps.findIndex((as) => as.action_order === actionStep.action_order);
        foundCountermeasure.action_steps.splice(actionStepIndex, 1, actionStep);

        // When a countermeasure has only one action step which gets activated, the entire countermeasure gets marked as activated
        if (foundCountermeasure.action_steps.length === 1)
            foundCountermeasure.status = actionStep.status;

        // Update the countermeasure in the list
        this.counterMeasures.splice(countermeasureIndex, 1, foundCountermeasure);
    }

    private copyToClipboard(value: string): void {
        Utilities.copyToClipboard(value);
    }
}
