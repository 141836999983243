import { AureliaConfiguration } from 'aurelia-configuration';
import { autoinject, containerless } from 'aurelia-framework';

declare var Headway: any;

@containerless()
@autoinject()
export class HeadwayWidget {
    constructor(
        private config: AureliaConfiguration
    ) { }

    public show(): void {
        Headway.show();
    }

    private attached(): void {
        this.initialise(this.config.get('headway.accountId'));
    }

    private initialise(accountId: string): void {
        const headwayConfig = {
            selector: '#headway-widget',
            account: accountId,
            // enabled: false
        };
        Headway.init(headwayConfig);
    }
}
