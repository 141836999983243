import { bindable } from 'aurelia-framework';
import { SicAlert, AlertDetailSchemaHistory } from 'services/cyber-api';

export class HistoryTimeline {
    @bindable() private history: AlertDetailSchemaHistory[];
    @bindable() private threat: SicAlert;
    @bindable() private isXChange: boolean = false;

    private shouldDisplayHistoryItem(historyItem: AlertDetailSchemaHistory, history: AlertDetailSchemaHistory[]): boolean {
        if (historyItem.entry_type === 'comment')
            if (history.some((item) =>
                // Check if the comment is similar to one of the history items' history_comments, if so exclude
                // them as we do not want to display duplicate comments. This means that the standalone comment is
                // already part of an action, be it closing or ignoring of the threat. The comment that is part of the
                // action is then shown alongside the status change activity item.
                item.history_comments && item.history_comments.some((hc) =>
                    hc.uuid === historyItem.comment.uuid)
            ))
                return false;

        return true;
    }
}
